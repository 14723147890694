.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.display_none{display: none;}

.text-group_title{
  font-size: 17px;
    font-weight: bold;
    text-transform: capitalize;
}
.margin_auto{
  margin: auto;
}

.gridbg2{
  background: #e5e5e5;
}
.form.emailinvteform {
  padding: 10px;
  border: 1px solid #ccc;
  padding-top: 38px;
  background: #f1f1f1;
  margin-bottom: 27px;
  border-radius: 6px;
}

.groupImage{
  width: 100px;
  height: 100px;
}

.width250{
  width: 250px !important;
}


.fadeIn{visibility: visible; animation-name: fadeIn;}
.jobs_header{visibility: visible; animation-name: fadeInUp;}
.jobs_header2{visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;}
.jobs_header3{visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;}
.img-52{width: 52px ;height: 52px;;}
.errorMsg {color: #eb0b0b;}
.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.alert{
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}


.btn-tags-skills {
  padding: 4px 12px;
}

.select_jobs_bysort{
  width: 160px;
  color: #4F5E64;
  line-height: 26px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
}



.wrapper-class {
  padding: 4px;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:#fff;
  padding: 5px;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.width_300px{
  width: 300px !important;
}

.width_100px{
  width: 100px !important;
}

.height_100px{
  height: 100px !important;
}

input[type='checkbox'] {
  margin-right: 5px;
    margin-top: 13px;
    width: 17px;
    height: 16px;
 }

#ispostjob_checkbo{
  margin-right: 5px;
    margin-top: 0px;
    width: 17px;
    height: 16px;
}

.lable_para{
  font-weight: bold;
}

.rmsc .dropdown-heading{
  height: 45px !important;
}
label.form-label {
  margin: 0px;
}

input.react-tagsinput-input {
  width: auto;
    display: inline-block;
    margin-bottom: 0;
    padding: 0;
    height: auto;
    border: none !important;
    margin-left: 10px;
}

.inputtypeTagsKey {
  border: 1px solid #198754 ;
  border-radius: 4px;
  padding: 8px 10px;
}

.inputtypeTagsKey span.react-tagsinput-tag {
  margin: 2px !important;
  background: #e8f0fe;
  color: #605dba;
  border: 1px solid #e8f0fe;
  font-size: 16px;
    padding: 5px;
}

.h6.text-capitlize , .text-capitlize {
  text-transform: capitalize;
}


.accept_agreeiment .form-check-input{
  margin-top: 4px;
}
.accept_agreeiment .invalid-feedback{
  margin-top: -10px;
}
.accept_agreeiment .text-small{
  font-weight: unset;
}

.remove_location_button {
  float: right;
  position: relative;
  margin-bottom: -20px;
  color: #f00;
  z-index: 99;
  cursor: pointer;
}

.add_more_location_btn{
  padding: 5px;
  font-size: 10px;
  margin-bottom: 20px;
}

.image-box .profile_pic{
  width: 51px;
  height: 50px;
}

.employers-info span.deletegrp {
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
}

.employers-info span.deletegrp i {
  color: #Fff !important;
  margin: 0;
  font-size: 14px;
}

.cursor_pointer{
  cursor: pointer !important;
}

button.btn.font-heading.btn-success {
  line-height: 2;
  color: #fff;
}

.btn-success{
  background:  #198754 !important ;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545 !important;
}

.connectionblock .connectionbtn button.btn {
  background: #605dba;
  color: #fff;
}

.text-blue-hyperlink{
  color: #605dba;
}

a.delete_link {
  padding: 4px 26px;
  margin-top: 5px;
  background: #dc3545;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
}

.passwordwrap .eyebtn {
  position: absolute;
  right: 10px;
  top: 32px;
  display: block;
  height: auto;
  cursor: pointer;
}

.eyebtn img{
  height: 13px;
}

.width-100{
  width: 100%;
}

.dashboard_img{
  max-height: 250px;
}

.imgsrc{
  width: 50px;
    height: 44px;
}

.paddingleft_25px{
  padding-left: 25px;
}

#Commentdropdown {
  margin-top: 0px;
}


.commentdown .media-block .mar-btm span {
  margin-bottom: 7px;
  display: inline-block;
  font-size: 16px;
  font-weight: 700 !important;
}

.commentdown .panel {
  box-shadow: none;
}

.topprimg img{
  width: 35px;
    height: 35px;
    border-radius: 50%;
}

select{
  height: 35px;
}

.jobseeker_img{
  width: 85px !important;
  height: 85px;
  border-radius: 50% !important;
}

.bg_imgupload{
  height: 150px;
    width: 100%;
}

.profile_imgupload {
  height: 75px;
  width: 75px;
  margin-top: 10px;
}

.langitem_text{
  height: 35px;
    width: 175px;
}

.similer_jobsImage {
  width: 85px;
  height: 60px;;
}

.error_class{
  border: 1px solid #f00 !important;
}

.chat_banner_img {
  border-radius: 52%;
}

span.tag-wrapper.ReactTags__tag {
  margin: 2px !important;
  background: #e8f0fe;
  color: #605dba;
  border: 1px solid #e8f0fe;
}

button.ReactTags__remove {
  width: 1px;
  height: 1px;
  border: navajowhite;
}
.job_image_show{
  margin-top: 10px;
  width: 100px;
  height: 100px;
}

.jobsickerbox .card-block-info img.dashboard_img{
  width: inherit !important;
  max-height: 422px;
}

li.p-2.border-bottom {
  padding-left: 10px !important;
}

li.p-2.border-bottom.active {
  background: #605dba;
  padding-left: 10px !important;
}

li.p-2.border-bottom.active p {
  color: #fff !important;
}

.readonly_text {
  background: #cccccc17 !important;
}

.jobbox_logo{
  font-size: 30px;font-weight: bold;color: #605dba;
}

.grouptabcon button.btn {
  padding: 6px 34px;
  font-size: 12px;
}

.disaplay_flex{
  display: flex;
}

.float_left{
  float: left;
}

.client_logo_slider .owl-carousel .owl-item img{
  height :100px;
}

.short_by_filter{
  width: 191px;
}

.pricing_plan_form .form-group {
  margin-bottom: 5px;
}


.sticky {
  position: sticky;
  top: 5px;
  z-index: 99;
}

.sticky_middle {
  position: sticky;
  top: 0px;
  z-index: 99;
  padding-bottom: 10px;
  border-bottom:1px solid #ccc ;
  background-color: #fff;
}

.no_notification_data{
  height: 400px;
  text-align: center;
  padding-top: 50px !important;
  font-size: 18px;
}
.notificationwraper .notification_loop_list a{
  color: #605dba;
  font-weight: bold;
}

tr.activeRow {
  background: #605dbab0;
}


.textblue{
  color: #605dbab0;
}

i.arrow.down {
  border: solid #605dba;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  margin-left: 12px;
  margin-top: 36px;
}

.block-pricing .box-info-price .text-price {
  font-size: 27px;
}
/* .jobsickerdashboard_btm{
   max-height: 800px;
   overflow-y: auto;
} */


.smallimage_add{
  height: 126px;
}

li.nav-item.current_active button {
  background: #e0e6f6 !important;
}

a.moreinforin_loop {
  cursor: pointer;
  font-weight: 500;
  border-bottom: 1px solid;
}


.applied_heading_seaction .applied_heading{
  position: absolute;
  padding-top: 16px;
}

.applied_heading_seaction .topsearch{
  width: 149px;
  margin-top: 3px;
}


select.form-control , select option {
  font-size: 14px;;
}


.mtm-20{
  margin-top:-20px
}

.signupbtn{
  font-weight: 600;
    color: #605dba;
}
/*scroll*/


/*------------*/
.filter-block  h5.medium-heading{
  background: #605dba;
  padding: 7px;
  color: #fff;
}
label.form-label {
  margin-top: 10px;
}

/*------------------------*/
.padding5percent{
  padding : 5% ;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #605dba;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
}




/*----------training and placement------------*/

 /* Custom styles */
 .details-section {
  margin-top: 5%;
  background-color: #605dba;
}

.text-white{
  color: #fff;
}
.main-head {
  background: #e4e0e0;
  padding: 1em 2em;
  margin-top: 0;
  position: absolute;
  width: 429px;
  /* right: 1em; */
}
.place-details h2 {
  color: #fff;
  font-family: 'OpenSans-Bold';
}
.you-learn {
  background: #fff;
  padding: 10px 0 0 10px;
}

.coursead_ex img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.placehoder_tags{
  background: rgb(5, 174, 229); color: rgb(255, 255, 255); padding: 2px 6px; border-radius: 15px; font-size: 15px;margin-right: 5px;;
}

.bgdd{
  background: #605dba21;
    padding: 17px !important;
    margin: 15px 0px;
}

.top_strips{
  background-color: #605dba;
  padding-top: 15px;
  padding-bottom: 15px;
}

.placement-box {
  background: #605dba;
}


.passcheck{
  margin-top: -15px;
    margin-bottom: 14px;
    color: #dc3545;
}


img.form_images {
    width: 100px;
}
.font18{
  font-size: 18px;
}
img.placement_show_img {
  max-height: 275px;
 padding: 10px;
}


.br-0{
  border-right: 0px !important;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (min-width: 576px) {
  .modal-dialog {
      max-width: 619px;
  }
}


@media only screen and (min-width:1024px) {
  .scroll_left_class {
      position: fixed;
      width: 264px;
      top: 10px;
    }
  .scroll_right_class{
    position: fixed;
    margin-top: -106px;
    width: 264px;
  }
  
  
  .jobs_left_scroll{
    position: fixed;
      margin-top: -407px;
      width: 20%;
      overflow: scroll;
      height: 800px;
  }
}


@media only screen and (min-width:992px) and (max-width:1199px){
  .scroll_left_class {
    width: 220px;
  }
}